import $ from 'jquery/dist/jquery.slim'
// import './jquery-3.2.1.js'
// import './jquery-3.2.1.min.js'
// import './jquery-3.2.1.slim.min.js'
// import './jquery.min.js'
// import './owl.carousel.js'

 $(document).ready(function(){
console.log('hola')
        $(window).scroll(function(){
          var barra = $(window).scrollTop();
          var posicion = barra * 0.20;
          $('.inicio').css({
            'background-position': '0 +' + posicion + 'px'
          });
        });
      });

      $(function(){
          $(window).scroll(function() {
              if ($(this).scrollTop() >= 10) {
                  $('header').addClass('stickytop');
              }
              else {
                  $('header').removeClass('stickytop');
              }
          });
      });      

      // $(document).ready(function() {


      //   // nosotros
      //   $('.cd-horizontal-timeline .timeline ol li > a').first().addClass('selected');
      //   $('.cd-horizontal-timeline .events-content ol li').first().addClass('selected');

      // })