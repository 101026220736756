// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-about-us-index-js": () => import("./../../../src/pages/en/about_us/index.js" /* webpackChunkName: "component---src-pages-en-about-us-index-js" */),
  "component---src-pages-en-clients-index-js": () => import("./../../../src/pages/en/clients/index.js" /* webpackChunkName: "component---src-pages-en-clients-index-js" */),
  "component---src-pages-en-contact-index-js": () => import("./../../../src/pages/en/contact/index.js" /* webpackChunkName: "component---src-pages-en-contact-index-js" */),
  "component---src-pages-en-contact-thank-you-js": () => import("./../../../src/pages/en/contact/thank-you.js" /* webpackChunkName: "component---src-pages-en-contact-thank-you-js" */),
  "component---src-pages-en-home-js": () => import("./../../../src/pages/en/home.js" /* webpackChunkName: "component---src-pages-en-home-js" */),
  "component---src-pages-en-human-talent-index-js": () => import("./../../../src/pages/en/human_talent/index.js" /* webpackChunkName: "component---src-pages-en-human-talent-index-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-infrastructure-index-js": () => import("./../../../src/pages/en/infrastructure/index.js" /* webpackChunkName: "component---src-pages-en-infrastructure-index-js" */),
  "component---src-pages-en-legal-notification-index-js": () => import("./../../../src/pages/en/legal_notification/index.js" /* webpackChunkName: "component---src-pages-en-legal-notification-index-js" */),
  "component---src-pages-en-news-index-js": () => import("./../../../src/pages/en/news/index.js" /* webpackChunkName: "component---src-pages-en-news-index-js" */),
  "component---src-pages-en-products-index-js": () => import("./../../../src/pages/en/products/index.js" /* webpackChunkName: "component---src-pages-en-products-index-js" */),
  "component---src-pages-en-quality-control-index-js": () => import("./../../../src/pages/en/quality_control/index.js" /* webpackChunkName: "component---src-pages-en-quality-control-index-js" */),
  "component---src-pages-en-search-index-js": () => import("./../../../src/pages/en/search/index.js" /* webpackChunkName: "component---src-pages-en-search-index-js" */),
  "component---src-pages-en-sustainability-index-js": () => import("./../../../src/pages/en/sustainability/index.js" /* webpackChunkName: "component---src-pages-en-sustainability-index-js" */),
  "component---src-pages-es-aviso-legal-index-js": () => import("./../../../src/pages/es/aviso-legal/index.js" /* webpackChunkName: "component---src-pages-es-aviso-legal-index-js" */),
  "component---src-pages-es-clientes-index-js": () => import("./../../../src/pages/es/clientes/index.js" /* webpackChunkName: "component---src-pages-es-clientes-index-js" */),
  "component---src-pages-es-contacto-gracias-js": () => import("./../../../src/pages/es/contacto/gracias.js" /* webpackChunkName: "component---src-pages-es-contacto-gracias-js" */),
  "component---src-pages-es-contacto-index-js": () => import("./../../../src/pages/es/contacto/index.js" /* webpackChunkName: "component---src-pages-es-contacto-index-js" */),
  "component---src-pages-es-gestion-de-calidad-index-js": () => import("./../../../src/pages/es/gestion-de-calidad/index.js" /* webpackChunkName: "component---src-pages-es-gestion-de-calidad-index-js" */),
  "component---src-pages-es-home-js": () => import("./../../../src/pages/es/home.js" /* webpackChunkName: "component---src-pages-es-home-js" */),
  "component---src-pages-es-index-js": () => import("./../../../src/pages/es/index.js" /* webpackChunkName: "component---src-pages-es-index-js" */),
  "component---src-pages-es-infraestructura-index-js": () => import("./../../../src/pages/es/infraestructura/index.js" /* webpackChunkName: "component---src-pages-es-infraestructura-index-js" */),
  "component---src-pages-es-inicio-js": () => import("./../../../src/pages/es/inicio.js" /* webpackChunkName: "component---src-pages-es-inicio-js" */),
  "component---src-pages-es-nosotros-index-js": () => import("./../../../src/pages/es/nosotros/index.js" /* webpackChunkName: "component---src-pages-es-nosotros-index-js" */),
  "component---src-pages-es-noticias-index-js": () => import("./../../../src/pages/es/noticias/index.js" /* webpackChunkName: "component---src-pages-es-noticias-index-js" */),
  "component---src-pages-es-productos-y-servicios-index-js": () => import("./../../../src/pages/es/productos-y-servicios/index.js" /* webpackChunkName: "component---src-pages-es-productos-y-servicios-index-js" */),
  "component---src-pages-es-search-index-js": () => import("./../../../src/pages/es/search/index.js" /* webpackChunkName: "component---src-pages-es-search-index-js" */),
  "component---src-pages-es-sostenibilidad-index-js": () => import("./../../../src/pages/es/sostenibilidad/index.js" /* webpackChunkName: "component---src-pages-es-sostenibilidad-index-js" */),
  "component---src-pages-es-talento-humano-index-js": () => import("./../../../src/pages/es/talento-humano/index.js" /* webpackChunkName: "component---src-pages-es-talento-humano-index-js" */),
  "component---src-pages-health-js": () => import("./../../../src/pages/health.js" /* webpackChunkName: "component---src-pages-health-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inicio-js": () => import("./../../../src/pages/inicio.js" /* webpackChunkName: "component---src-pages-inicio-js" */),
  "component---src-templates-article-index-en-js": () => import("./../../../src/templates/article/index_en.js" /* webpackChunkName: "component---src-templates-article-index-en-js" */),
  "component---src-templates-article-index-js": () => import("./../../../src/templates/article/index.js" /* webpackChunkName: "component---src-templates-article-index-js" */),
  "component---src-templates-category-index-en-js": () => import("./../../../src/templates/category/index_en.js" /* webpackChunkName: "component---src-templates-category-index-en-js" */),
  "component---src-templates-category-index-js": () => import("./../../../src/templates/category/index.js" /* webpackChunkName: "component---src-templates-category-index-js" */),
  "component---src-templates-tag-index-en-js": () => import("./../../../src/templates/tag/index_en.js" /* webpackChunkName: "component---src-templates-tag-index-en-js" */),
  "component---src-templates-tag-index-js": () => import("./../../../src/templates/tag/index.js" /* webpackChunkName: "component---src-templates-tag-index-js" */)
}

